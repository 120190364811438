import { Box, Container, darken } from '@mui/material'
import { Usps } from '../../Usps/Usps'
import { isValidUsps } from '../../../lib/craft/usps'
import { NavBar } from './PreHeader/NavBar'
import { Item as NavBarItem } from './PreHeader/NavBar/Item'
import { isValidMenu } from '../../../lib/craft/menus'
import { AnimatePresence, m } from 'framer-motion'
import { ReactNode } from 'react'
import { ContentEdgeCraftQuery } from '../../../graphql/ContentEdgeCraft.gql'

type PreHeaderProps = { edgeContent?: ContentEdgeCraftQuery | null }

export function PreHeader(props: PreHeaderProps) {
  const { edgeContent } = props

  let output: ReactNode = null

  if (edgeContent) {
    const { preHeaderUsps, preHeaderMenu } = edgeContent

    output = (
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ bounce: false, duration: 0.1 }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
          }}
        >
          {isValidUsps(preHeaderUsps) && (
            <Usps
              rotateActive={5000}
              usps={preHeaderUsps}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  justifyItems: 'center',
                },

                [theme.breakpoints.up('md')]: {
                  justifyItems: 'left',
                },
              })}
            />
          )}

          {isValidMenu(preHeaderMenu) && (
            <NavBar
              sx={(theme) => ({
                '& .DesktopNavItem-root': {
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 0,
                  paddingBottom: 0,
                  fontSize: '0.9rem',
                  color: theme.palette.primary.contrastText,
                },
              })}
            >
              {preHeaderMenu.menuItems.map((item) => {
                if (!item?.url) {
                  return null
                }

                let { url } = item

                if (url.startsWith('/#')) {
                  url = url.replace('/#', '#')
                }

                const handleClick = (e) => {
                  // console.log(url)
                  if (url.startsWith('#')) {
                    e.preventDefault()
                    const target = document.querySelector(url)
                    if (target) {
                      target.scrollIntoView({ behavior: 'smooth' })
                    }
                  }
                }

                return (
                  <NavBarItem key={`${item?.title}${item?.url}`} href={url} onClick={handleClick}>
                    {item?.title}
                  </NavBarItem>
                )
              })}
            </NavBar>
          )}
        </Box>
      </m.div>
    )
  }

  return (
    <Box
      sx={[
        (theme) => ({
          color: theme.palette.primary.contrastText,
          padding: `6px ${theme.page.horizontal}`,

          [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.background.brand,
          },

          [theme.breakpoints.down('md')]: {
            backgroundColor: darken(theme.palette.background.brand, 0.1),
            padding: theme.spacings.xs,
          },

          [theme.breakpoints.down('sm')]: {},
        }),
      ]}
    >
      <Container
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },

          [theme.breakpoints.up('md')]: {
            height: '1.5em',
          },
        })}
      >
        <AnimatePresence>{output}</AnimatePresence>
      </Container>
    </Box>
  )
}
