import React, { PropsWithChildren } from 'react'
import { Box, BoxProps } from '@mui/material'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

export type MenuTabsProps = PropsWithChildren<Pick<BoxProps, 'sx'>>

const { classes, selectors } = extendableComponent('DesktopNavBar', [
  'root',
  'scroller',
  'leftWrapper',
  'rightWrapper',
  'left',
  'right',
] as const)

export function NavBar(props: MenuTabsProps) {
  const { children, sx = [] } = props

  return (
    <Box
      className={classes.root}
      sx={[
        {
          width: '100%',
          display: { xs: 'none', md: 'flex' },
          position: 'relative',
          pointerEvents: 'all',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  )
}
NavBar.selectors = selectors
