import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Box, BoxProps, Theme } from '@mui/material'
import { CategoryBreadcrumb } from '../CategoryBreadcrumb'
import { CmsPageBreadcrumb } from '../CmsPageBreadcrumb'
import { ProductPageBreadcrumb } from '../ProductPageBreadcrumb'
import { DefaultBreadcrumb } from '../DefaultBreadcrumb'
import { CurrentPageProps } from '../../types'

export type BreadcrumbsProps = Pick<BoxProps, 'sx'> & Record<string, any> & CurrentPageProps

const { classes, selectors } = extendableComponent('Breadcrumbs', ['breadcrumbs'] as const)

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { sx = [], params, categories, products, filterTypes, currentPage } = props

  let specialBreadcrumb

  const category = categories?.items?.[0]
  const product = products?.items?.[0]
  const styles = [
    (theme: Theme) => ({
      '.MuiBreadcrumbs-separator': {
        fontSize: '0.6em',
        marginTop: '0.2em',
      },

      '.MuiBreadcrumbs-li': {
        typography: 'body2',
        fontSize: '0.6em',
      },

      '.MuiBreadcrumbs-li a': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    }),
  ]

  if (params && category && products?.items && filterTypes) {
    specialBreadcrumb = <CategoryBreadcrumb sx={styles} {...category} />
  } else if (product && !category && !filterTypes) {
    specialBreadcrumb = <ProductPageBreadcrumb sx={styles} {...product} />
  } else if ('pageEntries' in props) {
    const { pageEntries } = props
    if (pageEntries.length) {
      const page = pageEntries[0]
      specialBreadcrumb = <CmsPageBreadcrumb sx={styles} {...page} />
    }
  }

  if (!specialBreadcrumb && currentPage?.breadcrumbs) {
    specialBreadcrumb = <DefaultBreadcrumb sx={styles} {...currentPage} />
  }

  return (
    <Box className={classes.breadcrumbs} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {specialBreadcrumb}
    </Box>
  )
}

Breadcrumbs.selectors = selectors
