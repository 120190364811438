import { PropsWithChildren } from 'react'
import { Container, SxProps, Theme } from '@mui/material'

type HeaderContainerProps = PropsWithChildren<{
  sx?: SxProps<Theme>
}>

export function HeaderContainer(props: HeaderContainerProps) {
  const { children, sx } = props

  return (
    <Container
      sx={[
        (theme) => ({
          [theme.breakpoints.up('md')]: {
            padding: `${theme.spacings.xs} 0 0`,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Container>
  )
}
