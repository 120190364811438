import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  LayoutDefaultProps,
  iconCustomerService,
  iconHeart,
  MenuFabSecondaryItem,
  IconSvg,
  NavigationProvider,
  NavigationOverlay,
  useNavigationSelection,
  useMemoDeep,
} from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Divider } from '@mui/material'
import { useRouter } from 'next/router'
import { Footer } from './Footer'
import { LayoutDefault } from './LayoutDefault'
import { LayoutQuery } from './Layout.gql'
import { HeaderContainer } from './Header/HeaderContainer'
import { SearchForm } from './Header/SearchForm'
import { PostHeader } from './Header/PostHeader'
import { PreHeader } from './Header/PreHeader'
import { CartFab } from './CartFab'
import { NavigationFab } from './NavigationFab'
import { MobileMarkdownMenu } from './MobileMarkdownMenu'
import { DesktopMarkdownMenu } from './DesktopMarkdownMenu'
import useContentEdge from '../../hooks/ContentEdge/useContentEdge'
import { BackToTopFab } from './BackToTopFab'
import { UpperHeader } from './Header/UpperHeader'
import { NavigationSearchForm } from './Header/NavigationSearchForm'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const selection = useNavigationSelection()
  const router = useRouter()
  const { data: edgeContent } = useContentEdge()

  const { menu, children, ...uiProps } = props

  const { markdownMenu } = edgeContent || props

  const path = router.asPath.split('?')[0]

  return (
    <>
      <NavigationProvider
        selection={selection}
        items={useMemoDeep(
          () => [
            <NavigationSearchForm
              alwaysSearchEndAdornment
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Search for artworks',
                sx: (theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 'auto',
                  padding: 0,
                  marginRight: theme.spacings.xxxs,

                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'text.primary',
                    borderRadius: 0,
                    borderWidth: 1,
                  },

                  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'text.primary',
                    borderRadius: 0,
                    borderWidth: 1,
                  },

                  '& .MuiInputBase-root': {
                    padding: '0 0.2rem 0 0',

                    '& .MuiInputBase-input': {
                      whiteSpace: 'nowrap',
                      color: 'text.primary',
                      padding: '0.6rem 0.4rem 0.5rem',
                      fontSize: '0.9rem',

                      '&::placeholder': {
                        opacity: 1,
                        color: 'text.primary',
                        textTransform: 'uppercase',
                        letterSpacing: '0.02em',
                      },
                    },
                  },

                  '& svg': {
                    flex: '0 1 20px',
                    height: '20px',
                  },
                }),
              }}
            />,
            ...(!markdownMenu?.is_enabled ? magentoMenuToNavigation(menu, false) : []),
            ...(markdownMenu?.is_enabled ? [<MobileMarkdownMenu menu={markdownMenu.menu} />] : []),
            <Box
              sx={{
                my: 2,
              }}
            >
              <Divider sx={(theme) => ({ my: theme.spacings.xs })} />
            </Box>,
            <CustomerMenuFabItem
              key='account'
              guestHref='/account/signin'
              authHref='/account'
              sx={{ px: 0 }}
            >
              <Trans id='Account' />
            </CustomerMenuFabItem>,
            <MenuFabSecondaryItem
              key='service'
              icon={<IconSvg src={iconCustomerService} size='medium' />}
              href='/info/contact-us'
              sx={{ px: 0 }}
            >
              <Trans id='Contact' />
            </MenuFabSecondaryItem>,
            <WishlistMenuFabItem
              key='wishlist'
              icon={<IconSvg src={iconHeart} size='medium' />}
              sx={{ px: 0 }}
            >
              <Trans id='Wishlist' />
            </WishlistMenuFabItem>,
          ],
          [menu, markdownMenu],
        )}
      >
        <NavigationOverlay
          stretchColumns={false}
          variantSm='left'
          sizeSm='full'
          justifySm='start'
          itemWidthSm='min(100vw, max(320px, 70vw))'
          itemWidthMd='min(calc(100vw - 20px), 400px)'
          variantMd='left'
          sizeMd='full'
          justifyMd='start'
          mouseEvent='hover'
          itemPadding='md'
        />
      </NavigationProvider>

      <LayoutDefault
        {...uiProps}
        noSticky={path === '/'}
        beforeHeader={<PreHeader edgeContent={edgeContent} />}
        header={
          <HeaderContainer>
            <UpperHeader>
              <DesktopNavActions>
                {!path.startsWith('/search') && (
                  <SearchForm
                    textFieldProps={{
                      autoFocus: true,
                      placeholder: 'Search for artworks',
                      sx: (theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: 'auto',
                        padding: 0,
                        marginRight: theme.spacings.xxxs,

                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'text.primary',
                          borderRadius: 0,
                          borderWidth: 1,
                        },

                        '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'text.primary',
                          borderRadius: 0,
                          borderWidth: 1,
                        },

                        '& .MuiInputBase-root': {
                          padding: '0 0.2rem 0 0',

                          '& .MuiInputBase-input': {
                            whiteSpace: 'nowrap',
                            color: 'text.primary',
                            padding: '0.6rem 0.4rem 0.5rem',
                            fontSize: '0.9rem',

                            '&::placeholder': {
                              opacity: 1,
                              color: 'text.primary',
                              textTransform: 'uppercase',
                              letterSpacing: '0.02em',
                            },
                          },
                        },

                        '& svg': {
                          flex: '0 1 20px',
                          height: '20px',
                        },

                        [theme.breakpoints.between('md', 'lg')]: {
                          width: '250px',
                        },

                        [theme.breakpoints.up('lg')]: {
                          width: '340px',
                        },
                      }),
                    }}
                  />
                )}
                <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
                <CustomerFab guestHref='/account/signin' authHref='/account' />
                <CartFab />
              </DesktopNavActions>
            </UpperHeader>
            {!!markdownMenu?.is_enabled && !!markdownMenu?.menu && (
              <DesktopMarkdownMenu menu={markdownMenu?.menu} />
            )}
          </HeaderContainer>
        }
        footer={
          <>
            <BackToTopFab />
            <Footer {...uiProps} />
          </>
        }
        cartFab={<CartFab />}
        menuFab={<NavigationFab onClick={() => selection.set([])} />}
      >
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.page.horizontal,
              paddingRight: theme.page.horizontal,
            },
          })}
        >
          <PostHeader edgeContent={edgeContent} {...uiProps} />
          {children}
        </Box>
      </LayoutDefault>
    </>
  )
}
