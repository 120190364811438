import { useNavigation } from '@graphcommerce/next-ui/Navigation/hooks/useNavigation'
import { Box, styled } from '@mui/material'
import { MarkdownMenuFragment } from './MarkdownMenuFragment.gql'
import { modifyMenu } from './DesktopMarkdownMenu'
import { useRouter } from 'next/router'

const componentName = 'MobileMarkdownMenu' as const

type MobileMarkdownMenuProps = Omit<MarkdownMenuFragment, 'is_enabled'>

const MenuStyler = styled(Box, { name: componentName })(({ theme }) => ({
  padding: '0',
  margin: '0',
  listStyle: 'none',

  a: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: 'block',
  },

  'ul, li': {
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },

  '.cmsblock-item': {
    display: 'none',
  },

  '.level0': {
    padding: `${theme.spacings.xxxs} 0`,
  },

  '.level0.parent': {
    '> a, .all-link': {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
    },

    '.level1.submenu': {
      display: 'none',
      left: '0',
      right: '0',
      flexDirection: 'column',
      padding: `${theme.spacings.xxxs} 0 0 ${theme.spacing(2)}`,
      fontSize: theme.typography.pxToRem(15),

      '> .level1': {
        marginBottom: theme.spacings.xxxs,

        '> ul': {
          marginLeft: theme.spacings.sm,
        },

        '&:last-of-type': {
          marginBottom: '0',
        },

        '> span, strong': {
          display: 'block',
          marginBottom: '5px',
          textTransform: 'uppercase',
        },
      },
    },
  },
}))

export function MobileMarkdownMenu(props: MobileMarkdownMenuProps) {
  const router = useRouter()
  const { closing } = useNavigation()

  let { menu } = props
  menu = modifyMenu(menu)

  return (
    <Box component='nav' className='markdown-menu'>
      <MenuStyler
        component='ul'
        className='ui-menu level0'
        dangerouslySetInnerHTML={{ __html: menu }}
        onClick={async (e) => {
          let link: HTMLAnchorElement | null = null
          let submenu: HTMLUListElement | null = null

          if (e.target instanceof HTMLSpanElement) {
            const { parentNode } = e.target
            if (parentNode instanceof HTMLAnchorElement) {
              link = parentNode
            }
          } else if (e.target instanceof HTMLAnchorElement) {
            link = e.target
          }

          if (link?.nextElementSibling instanceof HTMLUListElement) {
            submenu = link?.nextElementSibling
          }

          const hasSubmenu = submenu?.classList.contains('level1')

          if (link && hasSubmenu && submenu) {
            e.preventDefault()
            if (!submenu.querySelector('.all-link')) {
              const allListItem = document.createElement('li')
              allListItem.classList.add('level1', 'text-item', 'ui-text-item')
              const allLink = document.createElement('a')
              allLink.classList.add('all-link')
              allLink.href = link.href
              allLink.innerText = `All ${link.innerText}`
              submenu.prepend(allListItem)
              allListItem.appendChild(allLink)
            }

            if (!link.classList.contains('active')) {
              submenu.style.display = 'flex'
              link.classList.add('active')
            } else {
              submenu.style.display = 'none'
              link.classList.remove('active')
            }
          } else if (link) {
            // go to link
            e.preventDefault()
            closing.set(true)

            if (!link.classList.contains('active')) {
              link.classList.add('active')
            } else {
              link.classList.remove('active')
            }

            await router.push(link.href)
          }
        }}
      />
    </Box>
  )
}
