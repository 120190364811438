import { useMotionValueValue } from '@graphcommerce/framer-utils'
import { Fab, Box, SxProps, Theme, FabProps } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { IconSvg } from '@graphcommerce/next-ui/IconSvg'
import { useScrollY } from '@graphcommerce/next-ui/Layout/hooks/useScrollY'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { useFabSize } from '@graphcommerce/next-ui/Theme'
import { iconMenu, iconClose } from '@graphcommerce/next-ui/icons'

export type NavigationFabProps = {
  menuIcon?: React.ReactNode
  closeIcon?: React.ReactNode
  sx?: SxProps<Theme>
} & Pick<FabProps, 'color' | 'size' | 'variant' | 'onClick'>

const name = 'MenuFab'
const parts = ['wrapper', 'fab', 'shadow', 'menu'] as const
type OwnerState = {
  scrolled: boolean
}

const { withState } = extendableComponent<OwnerState, typeof name, typeof parts>(name, parts)

export function NavigationFab(props: NavigationFabProps) {
  const { menuIcon, closeIcon, sx = [], ...fabProps } = props
  const router = useRouter()
  const [openEl, setOpenEl] = React.useState<null | HTMLElement>(null)

  const scrollY = useScrollY()
  const scrolled = useMotionValueValue(scrollY, (y) => y > 10)

  useEffect(() => {
    const clear = () => setOpenEl(null)
    router.events.on('routeChangeStart', clear)
    return () => router.events.off('routeChangeStart', clear)
  }, [router.events])

  const fabIconSize = useFabSize('responsive')

  const classes = withState({ scrolled })

  return (
    <Box
      sx={[
        { position: 'relative', width: fabIconSize, height: fabIconSize },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Fab
        color='inherit'
        aria-label='Open Menu'
        size='responsive'
        sx={(theme) => ({
          backgroundColor: `${theme.palette.background.brand} !important`,
          color: `${theme.palette.text.contrast} !important`,
        })}
        className={classes.fab}
        {...fabProps}
      >
        {closeIcon ?? (
          <IconSvg src={iconClose} size='large' sx={{ display: openEl ? 'block' : 'none' }} />
        )}
        {menuIcon ?? (
          <IconSvg src={iconMenu} size='large' sx={{ display: openEl ? 'none' : 'block' }} />
        )}
      </Fab>
    </Box>
  )
}
