import { Box, Container } from '@mui/material'
import { Usps } from '../../Usps/Usps'
import { isValidUsps } from '../../../lib/craft/usps'
import { AnimatePresence, m } from 'framer-motion'
import { ReactNode } from 'react'
import { ContentEdgeCraftQuery } from '../../../graphql/ContentEdgeCraft.gql'
import { Breadcrumbs } from './Breadcrumbs'
import { CurrentPageProps } from '../../types'

type PostHeaderProps = { edgeContent?: ContentEdgeCraftQuery | null } & CurrentPageProps

export function PostHeader(props: PostHeaderProps) {
  const { edgeContent, ...uiProps } = props

  let uspOutput: ReactNode = null

  if (edgeContent) {
    const { headerUsps } = edgeContent

    uspOutput = (
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ bounce: false, duration: 0.1 }}
      >
        {isValidUsps(headerUsps) && (
          <Usps
            rotateActive={5000}
            applyRotationDown='md'
            usps={headerUsps}
            sx={(theme) => ({
              whiteSpace: 'nowrap',

              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },

              [theme.breakpoints.between('md', 'mdlg')]: {
                maxWidth: '480px',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                rowGap: theme.spacings.xxs,
              },

              [theme.breakpoints.between('md', 'lg')]: {
                '& .MuiTypography-root': {
                  fontSize: '0.8em',
                },
              },

              [theme.breakpoints.between('sm', 'md')]: {
                justifyItems: 'flex-end',
              },
            })}
          />
        )}
      </m.div>
    )
  }

  return (
    <Container>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          padding: `${theme.spacings.xxxs} 0`,
          marginBottom: theme.spacings.sm,
          borderBottom: `1px solid ${theme.palette.divider}`,

          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: theme.spacings.xxs,
          },
        })}
      >
        <Breadcrumbs
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          })}
          {...uiProps}
        />
        <AnimatePresence>{uspOutput}</AnimatePresence>
      </Box>
    </Container>
  )
}
