import { Image } from '@graphcommerce/image'
import IconBasket from './Icon/basket.svg'
import IconBox from './Icon/box.svg'
import IconFeefo from './Icon/feefo.svg'
import IconHeart from './Icon/heart.svg'
import IconOwnArt from './Icon/own-art.svg'
import IconVan from './Icon/van.svg'
import IconBuyerProtection from './Icon/buyer-protection.svg'

type IconDefinition = {
  src: string
  widthMultiplier?: number
  scaleMultiplier?: number
}

const asIcons = <I,>(et: { [K in keyof I]: IconDefinition }) => et

const icons = asIcons({
  basket: {
    src: IconBasket,
  },
  box: {
    src: IconBox,
    widthMultiplier: 0.85,
    scaleMultiplier: 1.2,
  },
  feefo: {
    src: IconFeefo,
    widthMultiplier: 2.6,
    scaleMultiplier: 0.9,
  },
  heart: {
    src: IconHeart,
  },
  van: {
    src: IconVan,
    widthMultiplier: 1.3,
  },
  ownart: {
    src: IconOwnArt,
    widthMultiplier: 1.2,
  },
  buyerProtection: {
    src: IconBuyerProtection,
    widthMultiplier: 0.8,
    scaleMultiplier: 1.25,
  },
})

type IconProps = {
  icon?: keyof typeof icons | string | null
  alt?: string
}

export function Icon({ icon, alt = '' }: IconProps) {
  if (icon && icon in icons) {
    const { src, widthMultiplier = 1, scaleMultiplier = 1 } = icons[icon] as IconDefinition

    return (
      <Image
        src={src}
        alt={alt}
        layout='fixed'
        width={24 * widthMultiplier * scaleMultiplier}
        height={20 * scaleMultiplier}
        sx={{
          objectFit: 'cover',
        }}
        unoptimized
      />
    )
  }

  return null
}
