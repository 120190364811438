import { Trans } from '@lingui/react'
import { Breadcrumbs, BreadcrumbsProps, Link } from '@mui/material'
import PageLink from 'next/link'
import { PageContentQueryFragment } from '../CraftCMS/PageContentQueryFragment.gql'

export type PageContentType = NonNullable<NonNullable<PageContentQueryFragment['pageEntries']>[0]>

type CmsPageBreadcrumbProps = Omit<BreadcrumbsProps, 'children'> & PageContentType

export function CmsPageBreadcrumb(props: CmsPageBreadcrumbProps) {
  const { url, title, sx } = props

  return (
    <Breadcrumbs sx={sx} separator='>'>
      <PageLink href='/' passHref>
        <Trans id='Home' />
      </PageLink>
      {url !== 'page/homepage' && (
        <PageLink href={`/info/${url}`} passHref>
          {title}
        </PageLink>
      )}
    </Breadcrumbs>
  )
}
