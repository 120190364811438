import { useMotionValueValue } from '@graphcommerce/framer-utils'
import { Fab, styled, Box } from '@mui/material'
import { m } from 'framer-motion'
import React from 'react'
import { IconSvg } from '@graphcommerce/next-ui/IconSvg'
import { useScrollY } from '@graphcommerce/next-ui/Layout/hooks/useScrollY'
import { useFabAnimation } from '@graphcommerce/next-ui/LayoutParts/useFabAnimation'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { useFabSize } from '@graphcommerce/next-ui/Theme'
import { iconChevronUp } from '@graphcommerce/next-ui/icons'

const MotionDiv = styled(m.div)({})

const name = 'MenuFab'
const parts = ['wrapper', 'fab'] as const
type OwnerState = {
  scrolled: boolean
}

const { withState } = extendableComponent<OwnerState, typeof name, typeof parts>(name, parts)

export function BackToTopFab() {
  const scrollY = useScrollY()
  const scrolled = useMotionValueValue(scrollY, (y) => y > 10)
  const fabIconSize = useFabSize('responsive')

  const { opacity } = useFabAnimation()

  const classes = withState({ scrolled })

  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        width: fabIconSize,
        height: fabIconSize,
        zIndex: 'speedDial',

        [theme.breakpoints.up('sm')]: {
          bottom: theme.page.horizontal,
          left: theme.page.horizontal,
        },

        [theme.breakpoints.down('md')]: {
          bottom: 10,
          right: 10,
        },
      })}
    >
      <MotionDiv className={classes.wrapper} style={{ opacity }}>
        <Fab
          color='inherit'
          aria-label='Open Menu'
          size='responsive'
          sx={(theme) => ({
            boxShadow: 'none',
            '&:hover, &:focus': {
              boxShadow: 'none',
              background: theme.palette.background.brand,
            },
            background: theme.palette.background.brand,
            pointerEvents: 'all',
            color: theme.palette.text.contrast,
          })}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className={classes.fab}
        >
          <IconSvg src={iconChevronUp} size='large' />
        </Fab>
      </MotionDiv>
    </Box>
  )
}
