import {
  FormRow,
  iconClose,
  iconSearch,
  IconSvg,
  extendableComponent,
} from '@graphcommerce/next-ui'
import { useForm, useFormMuiRegister } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Box, IconButton, SxProps, TextField, TextFieldProps, Theme } from '@mui/material'
import { useRouter } from 'next/router'

export type SearchFormProps = {
  closeNavigation?: () => void
  totalResults?: number
  search?: string
  urlHandle?: string
  autoFocus?: boolean
  textFieldProps?: TextFieldProps
  sx?: SxProps<Theme>
  alwaysSearchEndAdornment?: boolean
}

const name = 'SearchForm' as const
const parts = ['root', 'totalProducts'] as const
const { classes } = extendableComponent(name, parts)

export function SearchForm(props: SearchFormProps) {
  const {
    closeNavigation,
    search = '',
    urlHandle = 'search',
    textFieldProps,
    alwaysSearchEndAdornment,
    sx = [],
  } = props
  const router = useRouter()

  const form = useForm({ mode: 'onSubmit', defaultValues: { search } })
  const { handleSubmit, formState, reset, watch, getValues } = form

  const muiRegister = useFormMuiRegister(form)

  const submit = handleSubmit((formData) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(`/${urlHandle}/${formData.search}`)
    reset(getValues())
    closeNavigation?.()
  })

  const handleReset = () => {
    reset({ search: '' })
  }

  const endAdornment =
    !watch('search') || alwaysSearchEndAdornment ? (
      <IconButton size='small' aria-label={i18n._(/* i18n */ 'Search')} type='submit'>
        <IconSvg src={iconSearch} />
      </IconButton>
    ) : (
      <IconButton onClick={handleReset} size='small'>
        <IconSvg src={iconClose} />
      </IconButton>
    )

  return (
    <Box className={classes.root} component='form' noValidate onSubmit={submit} sx={sx}>
      <FormRow>
        <TextField
          variant='outlined'
          type='text'
          placeholder={i18n._(/* i18n */ 'Search')}
          defaultValue={search}
          error={formState.isSubmitted && !!formState.errors.search}
          helperText={formState.isSubmitted && formState.errors.search?.message}
          {...muiRegister('search', { required: true, minLength: 2 })}
          InputProps={{ endAdornment }}
          {...textFieldProps}
        />
      </FormRow>
    </Box>
  )
}
