import PageLink from 'next/link'
import { Icon } from './Icon'
import { UspsItemsFragment } from './UspsQueryFragment.gql'
import { Box, SxProps, Theme, Link, List, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

export type UspsProps = {
  usps: UspsItemsFragment
  rotateActive?: number
  applyRotationDown?: keyof Theme['breakpoints']['values']
  sx?: SxProps<Theme>
  linkProps?: {
    sx?: SxProps<Theme>
  }
}

const listName = 'UspList' as const
const listParts = ['root'] as const
const { classes: listClasses } = extendableComponent(listName, listParts)

const listItemName = 'UspListItem' as const
const listItemParts = ['root', 'icon', 'text'] as const
const { classes: listItemClasses } = extendableComponent(listItemName, listItemParts)

export function Usps(props: UspsProps) {
  const [activeIndex, setActiveIndex] = useState(0)
  const { usps, sx, rotateActive, applyRotationDown, linkProps = {} } = props

  useEffect(() => {
    if (!rotateActive || !usps) {
      return () => {}
    }

    const activeTimer = setInterval(() => {
      setActiveIndex((prevActiveIndex) =>
        prevActiveIndex === usps.uspItems.length - 1 ? 0 : prevActiveIndex + 1,
      )
    }, rotateActive)

    return () => clearInterval(activeTimer)
  }, [usps, rotateActive])

  if (!usps) return null

  const rotationSx: SxProps<Theme> = {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',

    '& .UspListItem-root': {
      gridColumn: '1 / -1',
      gridRow: '1 / -1',
      opacity: 0,
      transition: 'opacity 200ms',

      '&--active': {
        opacity: 1,
      },
    },
  }

  const applyRotationSx: SxProps<Theme> = applyRotationDown
    ? (theme) => ({
        [theme.breakpoints.down('md')]: {
          ...rotationSx,
        },
      })
    : rotationSx

  return (
    <Box
      component='ul'
      className={listClasses.root}
      sx={[
        (theme) => ({
          display: 'flex',
          gap: theme.spacings.sm,
          listStyleType: 'none',
          padding: 0,
          margin: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
        ...(Array.isArray(applyRotationSx) ? applyRotationSx : [applyRotationSx]),
      ]}
    >
      {usps.uspItems.map((uspItemData, i) => {
        if (!uspItemData || !uspItemData) return null

        const { uspTitle: title, uspUrl: url, uspIcon: icon } = uspItemData

        if (!title) return null

        return (
          <Box
            component='li'
            className={`${listItemClasses.root} ${
              activeIndex === i ? `${listItemClasses.root}--active` : ''
            }`}
            key={title}
            sx={[
              (theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: theme.spacings.xxs,
                position: 'relative',

                '& > p': {
                  typography: 'body2',
                },
              }),
            ]}
          >
            {!!url && (
              <Link
                {...linkProps}
                href={url}
                sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              />
            )}
            <Box
              className={listItemClasses.icon}
              sx={{
                display: 'flex',

                '&:empty': {
                  display: 'none',
                },

                '& > * > img': {
                  display: 'block',
                },
              }}
            >
              <Icon icon={icon} alt={title} />
            </Box>
            <Box className={listItemClasses.text}>
              <Typography variant='body2'>{title}</Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
