import { Trans } from '@lingui/react'
import { Breadcrumbs, BreadcrumbsProps, Link } from '@mui/material'
import PageLink from 'next/link'
import { CurrentPageProps } from '../types'

type CmsPageBreadcrumbProps = Omit<BreadcrumbsProps, 'children'> & CurrentPageProps['currentPage']

export function DefaultBreadcrumb(props: CmsPageBreadcrumbProps) {
  const { breadcrumbs, sx } = props

  return (
    <Breadcrumbs sx={sx} separator='>'>
      <PageLink href='/' passHref>
        <Trans id='Home' />
      </PageLink>
      {breadcrumbs?.map((breadcrumb, i) => (
        <PageLink key={breadcrumb.url_path} href={`/${breadcrumb.url_path}`} passHref>
          {breadcrumb.title}
        </PageLink>
      ))}
    </Breadcrumbs>
  )
}
