import { usePrevPageRouter } from '@graphcommerce/framer-next-pages'
import { filterNonNullableKeys } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Breadcrumbs, BreadcrumbsProps, Link } from '@mui/material'
import PageLink from 'next/link'
import { productPageCategory } from '@graphcommerce/magento-product/components/ProductPageCategory/productPageCategory'
import { ProductPageBreadcrumbFragment } from '@graphcommerce/magento-product/components/ProductPageBreadcrumb/ProductPageBreadcrumb.gql'
import { ProductLinkFragment } from '@graphcommerce/magento-product/hooks/ProductLink.gql'
import { useProductLink } from '@graphcommerce/magento-product'

type ProductPageBreadcrumbsProps = ProductPageBreadcrumbFragment &
  Omit<ProductLinkFragment, 'uid'> &
  Omit<BreadcrumbsProps, 'children'>

export function ProductPageBreadcrumb(props: ProductPageBreadcrumbsProps) {
  const { categories, name, sx } = props
  const prev = usePrevPageRouter()

  const category =
    categories?.find((c) => `/${c?.url_path}` === prev?.asPath) ?? productPageCategory(props)

  const productLink = useProductLink(props)

  return (
    <Breadcrumbs sx={sx} separator='>'>
      <PageLink href='/' passHref>
        <Trans id='Home' />
      </PageLink>
      {filterNonNullableKeys(category?.breadcrumbs, ['category_level'])
        .sort((a, b) => a.category_level - b.category_level)
        .map((breadcrumb, i) => (
          <PageLink
            href={`/gallery/${breadcrumb.category_url_path}`}
            key={breadcrumb.category_uid}
            passHref
          >
            {breadcrumb.category_name}
          </PageLink>
        ))}

      {category && (
        <PageLink href={`/gallery/${category?.url_path}`} passHref>
          {category?.name}
        </PageLink>
      )}

      <PageLink href={productLink} passHref>
        {name}
      </PageLink>
    </Breadcrumbs>
  )
}
