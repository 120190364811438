import { WaitForQueries } from '@graphcommerce/ecommerce-ui'
import {
  extendableComponent,
  iconShoppingBag,
  DesktopHeaderBadge,
  IconSvg,
  useFabSize,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Fab, FabProps, Box, SxProps, Theme } from '@mui/material'
import PageLink from 'next/link'
import React from 'react'
import { useCartQuery } from '@graphcommerce/magento-cart/hooks/useCartQuery'
import { CartFabDocument } from '@graphcommerce/magento-cart/components/CartFab/CartFab.gql'
import { CartTotalQuantityFragment } from '@graphcommerce/magento-cart/components/CartFab/CartTotalQuantity.gql'

export type CartFabProps = {
  icon?: React.ReactNode
  sx?: SxProps<Theme>
} & Pick<FabProps, 'color' | 'size' | 'variant'>

type CartFabContentProps = CartFabProps & CartTotalQuantityFragment

const { classes, selectors } = extendableComponent('CartFab', ['root', 'cart', 'shadow'] as const)

function CartFabContent(props: CartFabContentProps) {
  const { total_quantity, icon, sx = [], ...fabProps } = props

  const cartIcon = icon ?? <IconSvg src={iconShoppingBag} size='large' />
  const fabIconSize = useFabSize('responsive')

  return (
    <Box
      className={classes.root}
      sx={[
        {
          position: 'relative',
          width: fabIconSize,
          height: fabIconSize,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <PageLink href='/cart' passHref>
        <Fab
          className={classes.cart}
          aria-label={i18n._(/* i18n */ 'Cart')}
          color='inherit'
          size='responsive'
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              backgroundColor: 'transparent',
            },

            [theme.breakpoints.down('md')]: {
              backgroundColor: `${theme.palette.background.brand} !important`,
              color: `${theme.palette.text.contrast} !important`,
            },
          })}
          {...fabProps}
        >
          <DesktopHeaderBadge
            color='primary'
            variant='standard'
            overlap='rectangular'
            badgeContent={total_quantity}
          >
            {cartIcon}
          </DesktopHeaderBadge>
        </Fab>
      </PageLink>
    </Box>
  )
}

/**
 * We give CartFab a bit of special handling. We don't want to make requests for this component
 * whilly nilly. We've imposed some limitations:
 *
 * We use useCartQuery that means that this will only execute when there is a cartId.
 *
 * We use fetchPolicy 'cache-only' so that when the cart comes into existence it will not
 * immediately start fetching. Why? There is a time between creating a cart and adding the first
 * product to the cart. This would mean that it would immediately start executing this query.
 */
export function CartFab(props: CartFabProps) {
  const cartQuery = useCartQuery(CartFabDocument, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <WaitForQueries waitFor={cartQuery} fallback={<CartFabContent {...props} total_quantity={0} />}>
      <CartFabContent total_quantity={cartQuery.data?.cart?.total_quantity ?? 0} {...props} />
    </WaitForQueries>
  )
}
