import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import {
  DesktopNavItemLinkProps,
  DesktopNavItemButtonProps,
} from '@graphcommerce/next-ui/LayoutParts/DesktopNavBarItem'
import { Link, SxProps, Theme } from '@mui/material'

function isLinkProps(
  props: DesktopNavItemLinkProps | DesktopNavItemButtonProps,
): props is DesktopNavItemLinkProps {
  return 'href' in props
}

const { classes, selectors } = extendableComponent('DesktopNavItem', ['root', 'line'] as const)

export function Item(props: DesktopNavItemLinkProps | DesktopNavItemButtonProps) {
  const { children, sx = [] } = props

  const linkSx: SxProps<Theme> = [
    (theme) => ({
      whiteSpace: 'nowrap',
      padding: `${theme.spacings.sm} ${theme.spacings.xxs}`,
      textTransform: 'uppercase',
    }),
    ...(Array.isArray(sx) ? sx : [sx]),
  ]

  if (isLinkProps(props)) {
    const { href } = props

    if (href) {
      return (
        <Link href={href} className={classes.root} sx={linkSx}>
          {children}
        </Link>
      )
    }
  }

  if ('onClick' in props && !isLinkProps(props)) {
    const { onClick } = props

    if (onClick) {
      return (
        <Link component='div' onClick={onClick} className={classes.root} sx={linkSx}>
          {children}
        </Link>
      )
    }
  }

  return null
}

Item.selectors = selectors
