import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { Logo } from '../Logo'

type HeaderContainerProps = PropsWithChildren

export function UpperHeader(props: HeaderContainerProps) {
  const { children } = props

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          padding: `0 ${theme.page.horizontal}`,
        },
      })}
    >
      <Logo />
      {children}
    </Box>
  )
}
