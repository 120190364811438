import { Box, styled } from '@mui/material'
import { nanoid } from 'nanoid/non-secure'
import { MarkdownMenuFragment } from './MarkdownMenuFragment.gql'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

const componentName = 'DesktopMarkdownMenu' as const

type DesktopMarkdownMenuProps = Omit<MarkdownMenuFragment, 'is_enabled'>

export const modifyMenu = (menuHtml: string) =>
  menuHtml
    .replace(/href="(https?:\/\/[^/]+)/g, 'href="')
    .replace(/href="\/([^\\."]*)\.html/g, 'href="/gallery/$1')

const MenuStyler = styled(Box, { name: componentName })(({ theme }) => ({
  display: 'flex',
  padding: '0',
  margin: '0',
  listStyle: 'none',

  [theme.breakpoints.down('mdlg')]: {
    justifyContent: 'space-between',
  },

  a: {
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    letterSpacing: '0.03em',
  },

  'ul, li': {
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },

  '.pagebuilder-mobile-only': {
    display: 'block !important',

    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },

  '.cmsblock-item': {
    '[data-content-type="image"]': {
      marginTop: '0',
      marginBottom: '0',
      marginLeft: 'auto',
      width: '100%',

      'img, a': {
        display: 'block',
        width: '100%',
      },
    },

    '&  ~ .link-item': {
      marginTop: theme.spacings.xxxs,
      textAlign: 'center',
    },
  },

  '.level0': {
    '> a': {
      [theme.breakpoints.down('mdlg')]: {
        padding: '0 10px',
      },

      [theme.breakpoints.up('mdlg')]: {
        padding: '0 20px',
      },
    },

    '&:first-of-type a': {
      paddingLeft: '0',
    },

    '&.parent': {
      'a.level-top': {
        textTransform: 'uppercase',
        marginBottom: theme.spacings.xs,
      },

      '.level1.submenu': {
        position: 'absolute',
        display: 'none',
        top: '100%',
        left: '0',
        right: '0',
        padding: `${theme.spacings.xs} 0 ${theme.spacings.md}`,
        columnGap: theme.spacings.xs,
        fontSize: theme.typography.pxToRem(15),

        '&:before, &:after': {
          content: `''`,
          display: 'block',
          position: 'absolute',
          width: '100vw',
          top: '0',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          zIndex: '-1',
          pointerEvents: 'none',
        },

        '&:before': {
          height: '100vh',
          background: 'rgba(0,0,0,0.72)',
        },

        '.cmsblock-item:nth-child(2):last-child': {
          flexBasis: '80%',
        },

        '.cmsblock-item:nth-child(3):last-child': {
          flexBasis: '60%',
        },

        '.cmsblock-item:nth-child(4):last-child': {
          flexBasis: '40%',
        },

        '.cmsblock-item:nth-child(5):last-child': {
          flexBasis: '20%',
        },

        '&:after': {
          height: '100%',
          background: '#FFF',
        },

        '.level1.parent': {
          flex: '1 1 0',

          '> span, strong': {
            display: 'block',
            marginBottom: theme.spacings.xxxs,
            textTransform: 'uppercase',
          },
        },
      },
    },
  },
}))

const resetDropdown = () => {
  document.querySelectorAll('li.parent.level0 a.active').forEach((el) => {
    el.classList.remove('active')

    const submenu = el.parentElement?.querySelector('.level1.submenu') as HTMLLIElement | undefined

    if (submenu) {
      submenu.style.display = 'none'
    }
  })

  document.body.classList.remove('nav-open')
}

const triggerDropdown = (levelTopLink: HTMLAnchorElement) => {
  if (
    !levelTopLink.classList.contains('active') &&
    document.querySelectorAll('a.level-top.active').length
  ) {
    resetDropdown()
  }

  const submenu = levelTopLink.parentElement?.querySelector('.submenu') as HTMLLIElement | undefined

  if (submenu) {
    levelTopLink.classList.add('active')
    submenu.style.display = 'flex'
    document.body.classList.add('nav-open')
  }
}

export function DesktopMarkdownMenu(props: DesktopMarkdownMenuProps) {
  const router = useRouter()

  let { menu } = props
  menu = modifyMenu(menu)

  const markdownMenuRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const element = markdownMenuRef.current

    if (element) {
      const levelTopItems = Array.from(element.querySelectorAll('.ui-menu-item.level-top')) as []

      levelTopItems.forEach((levelTopItem: HTMLElement) => {
        const primarySubmenu = levelTopItem.querySelector('.level1.submenu') as HTMLElement
        const levelTopItemAnchor = levelTopItem.querySelector(':scope > a') as HTMLElement

        if (
          levelTopItemAnchor &&
          primarySubmenu &&
          levelTopItemAnchor.parentNode &&
          levelTopItemAnchor.nextElementSibling &&
          levelTopItemAnchor.nextElementSibling.classList.contains('submenu')
        ) {
          const submenuId = `submenu-${nanoid(6)}`
          const topLevelLinkId = `top-level-link-${nanoid(6)}`
          const topLevelExpanderId = `top-level-expander-${nanoid(6)}`
          const submenuButton = document.createElement('button')
          const submenu = levelTopItemAnchor.nextElementSibling

          const submenuButtonAttributes: Map<string, string> = new Map([
            ['aria-controls', submenuId],
            ['aria-expanded', 'false'],
            ['aria-label', 'Show'],
            ['aria-labelledby', `${topLevelExpanderId} ${topLevelLinkId}`],
            ['id', topLevelExpanderId],
          ])

          for (const [key, value] of submenuButtonAttributes.entries()) {
            submenuButton.setAttribute(key, value)
          }

          submenuButton.classList.add('visually-hidden')
          submenu.setAttribute('id', submenuId)
          levelTopItemAnchor.setAttribute('id', topLevelLinkId)
          levelTopItemAnchor.parentNode.insertBefore(submenuButton, levelTopItemAnchor)
        }
      })
    }

    return () => {
      if (element) {
        element.querySelectorAll('button').forEach((el) => el.remove())
      }
    }
  }, [])

  return (
    <Box
      component='nav'
      className='markdown-menu'
      ref={markdownMenuRef}
      sx={(theme) => ({
        position: 'relative',
        maxWidth: theme.breakpoints.values.lg,
        margin: `${theme.spacings.xs} 0 0 0`,
        display: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.up('md')]: {
          display: 'block',
        },
      })}
    >
      <MenuStyler
        component='ul'
        className='ui-menu level0'
        role='menubar'
        onClick={async (e) => {
          if (
            e.target instanceof HTMLAnchorElement &&
            e.target.href.includes(window.location.origin)
          ) {
            resetDropdown()
            await router.push(e.target.href)
          } else if (e.target instanceof HTMLButtonElement) {
            const link = e.target.nextElementSibling as HTMLAnchorElement

            if (link) {
              resetDropdown()
              triggerDropdown(link)
            }
          }
        }}
        onMouseOver={(e) => {
          let link: HTMLAnchorElement | null

          if (
            e.target instanceof HTMLAnchorElement &&
            e.target.parentElement?.classList.contains('level-top')
          ) {
            link = e.target
          } else {
            link = (e.target as HTMLElement)
              .closest('li.level0')
              ?.querySelector(':scope > a') as HTMLAnchorElement | null
          }

          if (!link) {
            return
          }

          resetDropdown()
          triggerDropdown(link)
        }}
        onMouseLeave={(e) => {
          resetDropdown()
        }}
        dangerouslySetInnerHTML={{ __html: menu }}
      />
    </Box>
  )
}
